import { FavouritesResponse } from "./types";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

export const _removeItemFromFavourites = async (
  id: string
): Promise<FavouritesResponse> => {

  // await simulateApiError({ path: `/favourite/${id}` });

  const data = await fetchAPI2<FavouritesResponse>(`/favourite/${id}`, {
    method: "DELETE",
  });

  return data;
};
