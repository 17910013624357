import { FavouriteTypeOption, FavouritesResponse } from "./types";
import { PT } from "~/utils/products";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

type BulkAddFavouritesResponse = string[];

export const _bulkAddFavourites = async (
  payload: { SKU: string; Type: FavouriteTypeOption }[]
): Promise<BulkAddFavouritesResponse> => {
  // await simulateApiError({path: "favourite/import"});

  const data = await fetchAPI2<
    BulkAddFavouritesResponse,
    { SKU: string; Type: FavouriteTypeOption }[]
  >("/favourite/import", { method: "POST", body: payload });

  return data;
};
