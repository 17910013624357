import { getSession } from "~/services/session";
import { FavouritesResponse } from "./types";
import { fetchAPI } from "../fetchAPI";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

export const _retrieveFavourites = async (): Promise<FavouritesResponse> => {
  const session = await getSession();

  if (!session) {
    if (import.meta.env.DEV) {
      console.log(
        "[_retrieveFavourites()]: No session found. Returning empty data."
      );
    }
    // TODO - return empty data or throw error??
    return { Total: 0, Results: [] };
  }

  try {
    // await simulateApiError({path: "/favourite"})
    const data = await fetchAPI2<FavouritesResponse>("/favourite");

    return data;
  } catch (error) {
    if (import.meta.env.DEV) {
      console.error("[_retrieveFavourites()]: ", error);
    }
    throw error;
  }
};