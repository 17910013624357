import { query, createAsync } from "@solidjs/router";
import { _retrieveFavourites } from "../roma-api/favourites";
import { getSession } from "../session";
import { type FavouritesResponse } from "../roma-api/favourites/types";

const turnFavsIntoMap = (favourites: FavouritesResponse["Results"]) => {
  const map = new Map();

  favourites.forEach((item) => {
    map.set(`${item.SKU}-${item.Type}`, { ID: item.ID });
  });

  return map;
};

export const getFavourites = query(async () => {
  const session = await getSession();
  if (!session) {
    if (import.meta.env.DEV) {
      console.log("[getFavourites()]: No session found - early return");
    }
    return;
  }

  try {
    const favourites = await _retrieveFavourites();

    const map = turnFavsIntoMap(favourites.Results);
    return map;
  } catch (error) {
    if (import.meta.env.DEV) {
      console.log(
        "[getFavourites()]: Error caught: ",
        error
      );
    }
    throw error;
  }
}, "favourites");

// TODO - create the hasFavourite/isFavourite fn to check the cache and return the id (needed to delete)
// ?to be used/passed in the Session context?
export const useFavourites = (
  options: { deferStream: boolean } = { deferStream: false }
) =>
  createAsync(async () => {
    getFavourites();
  }, options);

// export const useFavouritesWithInitial = () =>
//   createAsync(() => getFavourites(), {
//     initialValue: new Map(),
//     ssrLoadFrom: "initial",
//   });
