import { action, revalidate, reload, json } from "@solidjs/router";
import {
  _bulkAddFavourites,
} from "../roma-api/favourites";
import { FavouriteTypeOption } from "../roma-api/favourites/types";
import { getFavourites } from "./useFavourites";

export const importBulkFavouritesAction = action(
  async (payload: { SKU: string; Type: FavouriteTypeOption }[]) => {
    const response = await _bulkAddFavourites(payload);

    return json(response, { revalidate: getFavourites.key });
    // return reload({revalidate: getFavourites.key })
  }
);
