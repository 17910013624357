import { Permission } from ".";
import { getSession } from "../session";

export const getPermissions = async () => {
  try {
    const session = await getSession();
    if (!session || !session.permissions) {
      if (import.meta.env.DEV) {
        console.log("[getPermissions.ts]: No session/permissons found");
      }
      throw new Error("No session/permissions found.");
    }

    // TODO - why Set?
    const permissionSet = new Set(session.permissions);

    return permissionSet;
  } catch (error) {
    return new Set() as unknown as Permission[];
  }
};
