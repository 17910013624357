import { FavouriteTypeOption, FavouritesResponse } from "./types";
import { PT } from "~/utils/products";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

export const _sendItemToFavourites = async (
  sku: string,
  type: FavouriteTypeOption = PT.MOULDING
): Promise<FavouritesResponse> => {

  // await simulateApiError({path: "favourite"});

  const data = await fetchAPI2<
    FavouritesResponse,
    { SKU: string; Type: FavouriteTypeOption }
  >("/favourite", { method: "POST", body: { SKU: sku, Type: type } });

  return data;
};
