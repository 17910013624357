import { getSession } from "~/services/session";
import type { CartSummary } from "./types";
import type { ListResponse } from "../products/types";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError, APIError } from "../errors";

export type ListCartResponse = Pick<
  ListResponse<CartSummary>,
  "Results" | "Total"
>;

export const _listCarts = async (): Promise<ListCartResponse> => {
  try {
    const session = await getSession();

    if (!session?.token) {
      if (import.meta.env.DEV) {
        console.log("[listCarts.ts]: No session/token found");
      }
      // @ts-expect-error
      // TODO
      return;
    }

    // await simulateApiError({
    //   path: "/cart",
    //   statusCode: 410,
    //   message: "Could not retrieve cart list",
    //   code: "SOME_ERROR_CODE",
    // });

    const data = await fetchAPI2<ListCartResponse>(`/cart`);

    return data;
  } catch (error) {
    console.log("Catching Error in '_listCarts', error:", error);
    throw error;
  }
};
