import { getSession } from "../session";
import { APIError } from "./errors";

export type FetchOptions<T = undefined> = {
  method?: "GET" | "POST" | "PATCH" | "DELETE" | "PUT";
  body?: T;
  token?: string;
  /**
   * If true, fetch request will not throw if a token is not provided or found
   */
  unauthenticated?: boolean;
};

/**
 * A helper function that automatically uses the users token
 * to call the API directly.
 * @param input The request body to send to the API.
 * @returns Returns the supplied body of the service
 */
export async function fetchAPI2<U, T = undefined>(
  path: string,
  options: FetchOptions<T> = { method: "GET" }
): Promise<U> {
  const { method = "GET", body, unauthenticated, ...extra } = options;
  if (import.meta.env.DEV) {
    console.log(`fetchAPI-2: [${method}] ${path}`);
  }

  const headers: Record<string, string> = {};

  let userId: string | undefined;
  let token: string | undefined;

  // Handle authentication
  if (unauthenticated !== true) {
    try {
      const session = await getSession();
      token = options.token ?? session?.token;
      userId = session?.user_id;

      // const token =
      //   options.token ?? (await getSession().then((data) => data?.token));
      if (!token) {
        throw APIError.authenticationError(path, {
          method,
          userId,
          requestBody: body,
        });
      }
      headers["Authorization"] = `Bearer ${token}`;
    } catch (error) {
      // If getSession() throws its own error, wrap it
      if (!(error instanceof APIError)) {
        throw APIError.authenticationError(path);
      }
      throw error;
    }
  }

  // Handle content type
  if (typeof body === "object") {
    headers["Content-Type"] = "application/json";
  }

  const init: RequestInit = {
    method,
    headers: {
      ...headers,
    },
    ...extra,
  };

  // Handle request body
  if (body != null) {
    try {
      if (typeof body === "object") {
        init.body = JSON.stringify(body);
      } else if (typeof body === "string") {
        init.body = body;
      } else {
        throw APIError.invalidBodyError(body, {
          method,
          userId,
        });
      }
    } catch (error) {
      throw APIError.invalidBodyError(body, {
        method,
        userId,
      });
    }
  }

  // Make the request
  let response: Response;
  try {
    response = await fetch(`${import.meta.env.VITE_ROMA_API}${path}`, init);
  } catch (error) {
    // Handle network errors (offline, DNS failure, etc)
    throw APIError.networkError(path, error as Error, {
      method,
      requestBody: body,
      userId,
    });
  }

  // Handle successful responses
  if (response.ok) {
    const contentType = response.headers.get("content-type");
    try {
      if (contentType?.includes("application/json")) {
        return (await response.json()) as U;
      }
      if (contentType?.includes("application/pdf")) {
        return (await response.blob()) as U;
      }
      return (await response.text()) as U;
    } catch (error) {
      throw APIError.parseError(path, contentType, error as Error, {
        method,
        requestBody: body,
        userId,
      });
    }
  }

  // Handle error responses
  let errorData;
  try {
    errorData = await response.json();
  } catch (error) {
    // If we can't parse the error response as JSON
    throw new APIError({
      code: "UNKNOWN_ERROR",
      message: `HTTP error ${response.status}`,
      statusCode: response.status,
      path,
      details: {
        responseText: await response
          .text()
          .catch(() => "Unable to read response text"),
        originalError: error instanceof Error ? error.message : "Unknown error",
        method,
        requestBody: body,
        userId,
      },
    });
  }

  // Throw appropriate APIError for error responses
  throw APIError.fromResponse(errorData, response.status, path, {
    method,
    requestBody: body,
    userId,
  });
}
